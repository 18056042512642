import '@/locales/i18n';
import ThemeProvider from '@/theme';
import Router from '@/routes/sections';
import 'simplebar-react/dist/simplebar.min.css';
import { LocalizationProvider } from '@/locales';
import ProgressBar from '@/components/progress-bar';
import { SnackbarProvider } from '@/components/snackbar';
import { useScrollToTop } from '@/hooks/use-scroll-to-top';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { MotionLazy } from '@/components/animate/motion-lazy';
import { AuthConsumer, AuthProvider } from '@/auth/context/jwt';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { SettingsDrawer, SettingsProvider } from './components/settings';
import { ENVIRONMENT } from './config-global';

const queryClient = new QueryClient();

Sentry.init({
  enabled: ENVIRONMENT !== 'local',
  dsn: 'https://6cfd778908579be4dfbcc5f878935fed@o4507051201003520.ingest.us.sentry.io/4507051243864064',

  environment: ENVIRONMENT,

  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  profilesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/hub.orion\.global\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export default function App() {
  useScrollToTop();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LocalizationProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: true,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <SettingsDrawer />
                  <ProgressBar />
                  <AuthConsumer>
                    {/* <Provider store={store}> */}
                    <Router />
                    {/* </Provider> */}
                  </AuthConsumer>
                </SnackbarProvider>
              </MotionLazy>
              <ReactQueryDevtools initialIsOpen={false} />
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
