import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard, PermissionGuard } from '@/auth/guard';
import DashboardLayout from '@/layouts/management';
import { LoadingScreen } from '@/components/loading-screen';
import { OauthCallbackPage } from '@/pages/auth/oauth/oauthCallback';
import { retry } from '@/utils/common';
import AdminGuard from '@/auth/guard/admin-guard';

const AccountGeneralPage = lazy(
  () =>
    retry(() => import('@/pages/account/account-general')) as Promise<
      typeof import('@/pages/account/account-general')
    >
);
const AccountSecurityPage = lazy(
  () =>
    retry(() => import('@/pages/account/account-security')) as Promise<
      typeof import('@/pages/account/account-security')
    >
);
const AccountNotificationsPage = lazy(
  () =>
    retry(() => import('@/pages/account/account-notifications')) as Promise<
      typeof import('@/pages/account/account-notifications')
    >
);

const UserListPage = lazy(
  () =>
    retry(() => import('@/pages/management/users/list')) as Promise<
      typeof import('@/pages/management/users/list')
    >
);
const UserCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/users/create')) as Promise<
      typeof import('@/pages/management/users/create')
    >
);
const UserEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/users/edit')) as Promise<
      typeof import('@/pages/management/users/edit')
    >
);

const UsMktListPage = lazy(
  () =>
    retry(() => import('@/pages/management/users-mkt/usermkt-list')) as Promise<
      typeof import('@/pages/management/users-mkt/usermkt-list')
    >
);
const UsMktCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/users-mkt/usermkt-create')) as Promise<
      typeof import('@/pages/management/users-mkt/usermkt-create')
    >
);
const UsMktEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/users-mkt/usermkt-edit')) as Promise<
      typeof import('@/pages/management/users-mkt/usermkt-edit')
    >
);

const RoleListPage = lazy(
  () =>
    retry(() => import('@/pages/management/roles/list')) as Promise<
      typeof import('@/pages/management/roles/list')
    >
);
const RoleCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/roles/create')) as Promise<
      typeof import('@/pages/management/roles/create')
    >
);
const RoleEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/roles/edit')) as Promise<
      typeof import('@/pages/management/roles/edit')
    >
);

const TeamListPage = lazy(
  () =>
    retry(() => import('@/pages/management/teams/list')) as Promise<
      typeof import('@/pages/management/teams/list')
    >
);
const TeamCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/teams/create')) as Promise<
      typeof import('@/pages/management/teams/create')
    >
);
const TeamEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/teams/edit')) as Promise<
      typeof import('@/pages/management/teams/edit')
    >
);

const OrganizationCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/create')) as Promise<
      typeof import('@/pages/management/organizations/create')
    >
);
const OrganizationListPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/list')) as Promise<
      typeof import('@/pages/management/organizations/list')
    >
);
const OrganizationEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/edit')) as Promise<
      typeof import('@/pages/management/organizations/edit')
    >
);
const OrganizationTabsPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/tabs')) as Promise<
      typeof import('@/pages/management/organizations/tabs')
    >
);
const OrganizationInformationEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/information-edit')) as Promise<
      typeof import('@/pages/management/organizations/information-edit')
    >
);
const LegalEntityCreatePage = lazy(
  () =>
    retry(() => import('@/pages/setting/legal-entities/create')) as Promise<
      typeof import('@/pages/setting/legal-entities/create')
    >
);
const LegalEntityEditPage = lazy(
  () =>
    retry(() => import('@/pages/setting/legal-entities/edit')) as Promise<
      typeof import('@/pages/setting/legal-entities/edit')
    >
);

const PartnerListPage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/list')) as Promise<
      typeof import('@/pages/management/partners/list')
    >
);
const PartnerCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/create')) as Promise<
      typeof import('@/pages/management/partners/create')
    >
);
const PartnerEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/edit')) as Promise<
      typeof import('@/pages/management/partners/edit')
    >
);
const PartnerTabsPage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/tabs')) as Promise<
      typeof import('@/pages/management/partners/tabs')
    >
);
const PartnerContactCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/contact-create')) as Promise<
      typeof import('@/pages/management/partners/contact-create')
    >
);
const PartnerContactEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/contact-edit')) as Promise<
      typeof import('@/pages/management/partners/contact-edit')
    >
);
const PartnerInformationEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/information-edit')) as Promise<
      typeof import('@/pages/management/partners/information-edit')
    >
);
const OrganizationDomainCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/domain/domain-create')) as Promise<
      typeof import('@/pages/management/organizations/domain/domain-create')
    >
);
const OrganizationDomainEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/domain/domain-edit')) as Promise<
      typeof import('@/pages/management/organizations/domain/domain-edit')
    >
);
const OrganizationOrderCreateView = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/order/order-create')) as Promise<
      typeof import('@/pages/management/organizations/order/order-create')
    >
);
const OrganizationOrderEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/order/order-edit')) as Promise<
      typeof import('@/pages/management/organizations/order/order-edit')
    >
);
const ContactListPage = lazy(
  () =>
    retry(() => import('@/pages/management/contacts/list')) as Promise<
      typeof import('@/pages/management/contacts/list')
    >
);
const ContactCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/contacts/create')) as Promise<
      typeof import('@/pages/management/contacts/create')
    >
);
const ContactEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/contacts/edit')) as Promise<
      typeof import('@/pages/management/contacts/edit')
    >
);
const BankListPage = lazy(
  () =>
    retry(() => import('@/pages/management/banks/list')) as Promise<
      typeof import('@/pages/management/banks/list')
    >
);
const BankCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/banks/create')) as Promise<
      typeof import('@/pages/management/banks/create')
    >
);
// const ContactEditPage = lazy(
//   () =>
//     retry(() => import('@/pages/management/contacts/edit')) as Promise<
//       typeof import('@/pages/management/contacts/edit')
//     >
// );
const OrganizationContactCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/contact/contact-create')) as Promise<
      typeof import('@/pages/management/organizations/contact/contact-create')
    >
);
const OrganizationContactEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/contact/contact-edit')) as Promise<
      typeof import('@/pages/management/organizations/contact/contact-edit')
    >
);
const OrganizationUserEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/users/user-edit')) as Promise<
      typeof import('@/pages/management/organizations/users/user-edit')
    >
);

const OrganizationUserCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/users/user-create')) as Promise<
      typeof import('@/pages/management/organizations/users/user-create')
    >
);

const ProductListPage = lazy(
  () =>
    retry(() => import('@/pages/management/products/list')) as Promise<
      typeof import('@/pages/management/products/list')
    >
);
const ProductCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/products/create')) as Promise<
      typeof import('@/pages/management/products/create')
    >
);
const ProductEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/products/edit')) as Promise<
      typeof import('@/pages/management/products/edit')
    >
);
const SkuListPage = lazy(
  () =>
    retry(() => import('@/pages/management/skus/list')) as Promise<
      typeof import('@/pages/management/skus/list')
    >
);
const SkuCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/skus/create')) as Promise<
      typeof import('@/pages/management/skus/create')
    >
);
const SkuEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/skus/edit')) as Promise<
      typeof import('@/pages/management/skus/edit')
    >
);
const PlanListPage = lazy(
  () =>
    retry(() => import('@/pages/management/plans/list')) as Promise<
      typeof import('@/pages/management/plans/list')
    >
);
const PlanCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/plans/create')) as Promise<
      typeof import('@/pages/management/plans/create')
    >
);
const PlanEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/plans/edit')) as Promise<
      typeof import('@/pages/management/plans/edit')
    >
);
const SubscriptionListPage = lazy(
  () =>
    retry(() => import('@/pages/management/subscriptions/list')) as Promise<
      typeof import('@/pages/management/subscriptions/list')
    >
);
const SubscriptionCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/subscriptions/create')) as Promise<
      typeof import('@/pages/management/subscriptions/create')
    >
);
const SubscriptionEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/subscriptions/edit')) as Promise<
      typeof import('@/pages/management/subscriptions/edit')
    >
);
const OrganizationSubscriptionCreatePage = lazy(
  () =>
    retry(
      () => import('@/pages/management/organizations/subscription/subscription-create')
    ) as Promise<typeof import('@/pages/management/organizations/subscription/subscription-create')>
);
const OrganizationSubscriptionEditPage = lazy(
  () =>
    retry(
      () => import('@/pages/management/organizations/subscription/subscription-edit')
    ) as Promise<typeof import('@/pages/management/organizations/subscription/subscription-edit')>
);
const SubscriptionInformationPage = lazy(
  () =>
    retry(() => import('@/pages/management/subscriptions/information')) as Promise<
      typeof import('@/pages/management/subscriptions/information')
    >
);
const SubscriptionOrderPage = lazy(
  () =>
    retry(() => import('@/pages/management/subscriptions/orders')) as Promise<
      typeof import('@/pages/management/subscriptions/orders')
    >
);
const OrganizationSubscriptionInformationPage = lazy(
  () =>
    retry(
      () => import('@/pages/management/organizations/subscription/subscription-information')
    ) as Promise<
      typeof import('@/pages/management/organizations/subscription/subscription-information')
    >
);
const OrganizationSubscriptionOrderPage = lazy(
  () =>
    retry(
      () => import('@/pages/management/organizations/subscription/subscription-orders')
    ) as Promise<typeof import('@/pages/management/organizations/subscription/subscription-orders')>
);
const PartnerProviderCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/provider-create')) as Promise<
      typeof import('@/pages/management/partners/provider-create')
    >
);
const PartnerProviderEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/partners/provider-edit')) as Promise<
      typeof import('@/pages/management/partners/provider-edit')
    >
);
const PricebookListPage = lazy(
  () =>
    retry(() => import('@/pages/management/pricebook/list')) as Promise<
      typeof import('@/pages/management/pricebook/list')
    >
);
const PricebookCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/pricebook/create')) as Promise<
      typeof import('@/pages/management/pricebook/create')
    >
);
const PricebookEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/pricebook/edit')) as Promise<
      typeof import('@/pages/management/pricebook/edit')
    >
);
const OrderListPage = lazy(
  () =>
    retry(() => import('@/pages/management/orders/list')) as Promise<
      typeof import('@/pages/management/orders/list')
    >
);
const OrderEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/orders/edit')) as Promise<
      typeof import('@/pages/management/orders/edit')
    >
);
const OrderCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/orders/create')) as Promise<
      typeof import('@/pages/management/orders/create')
    >
);
const OrganizationDocumentEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/document/document-edit')) as Promise<
      typeof import('@/pages/management/organizations/document/document-edit')
    >
);
const OrganizationDocumentCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/organizations/document/document-create')) as Promise<
      typeof import('@/pages/management/organizations/document/document-create')
    >
);

const RequestListPage = lazy(
  () =>
    retry(() => import('@/pages/management/requests/list')) as Promise<
      typeof import('@/pages/management/requests/list')
    >
);
const RequestEditPage = lazy(
  () =>
    retry(() => import('@/pages/management/requests/edit')) as Promise<
      typeof import('@/pages/management/requests/edit')
    >
);
const RequestCreatePage = lazy(
  () =>
    retry(() => import('@/pages/management/requests/create')) as Promise<
      typeof import('@/pages/management/requests/create')
    >
);
const RequestUserListView = lazy(
  () =>
    retry(() => import('@/pages/management/requests/users/list')) as Promise<
      typeof import('@/pages/management/requests/users/list')
    >
);
const RequestUserDetailView = lazy(
  () =>
    retry(() => import('@/pages/management/requests/users/detail')) as Promise<
      typeof import('@/pages/management/requests/users/detail')
    >
);
const PayerAccountEditPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/payer-accounts/edit')) as Promise<
      typeof import('@/pages/cloud-compass/payer-accounts/edit')
    >
);
const PayerAccountCreatePage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/payer-accounts/create')) as Promise<
      typeof import('@/pages/cloud-compass/payer-accounts/create')
    >
);
const PayerAccountListView = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/payer-accounts/list')) as Promise<
      typeof import('@/pages/cloud-compass/payer-accounts/list')
    >
);
const PayerAccountTabsPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/payer-accounts/tabs')) as Promise<
      typeof import('@/pages/cloud-compass/payer-accounts/tabs')
    >
);
const LinkedAccountEditPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/linked-accounts/edit')) as Promise<
      typeof import('@/pages/cloud-compass/linked-accounts/edit')
    >
);
const LinkedAccountInformationPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/linked-accounts/information')) as Promise<
      typeof import('@/pages/cloud-compass/linked-accounts/information')
    >
);
const BillingAccountListView = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/billing-accounts/list')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/billing-accounts/list')
    >
);
const BillingAccountTabsPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/billing-accounts/tabs')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/billing-accounts/tabs')
    >
);
const BillingAccountCreatePage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/billing-accounts/create')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/billing-accounts/create')
    >
);
const BillingAccountEditPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/billing-accounts/edit')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/billing-accounts/edit')
    >
);
const SubAccountListView = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/subaccounts/list')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/subaccounts/list')
    >
);
const SubAccountTabsPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/subaccounts/tabs')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/subaccounts/tabs')
    >
);
const SubAccountEditPage = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/subaccounts/edit')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/subaccounts/edit')
    >
);
const ProjectListView = lazy(
  () =>
    retry(() => import('@/pages/cloud-compass/gcp/projects/list')) as Promise<
      typeof import('@/pages/cloud-compass/gcp/projects/list')
    >
);

export const managementRoutes = [
  {
    path: 'login',
    element: <Outlet />,
    children: [
      {
        path: 'oauth',
        element: <OauthCallbackPage />,
      },
    ],
  },
  {
    path: 'management',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'accounts',
        children: [
          {
            path: 'organizations',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationListPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="update">
                    <OrganizationEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/information/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationInformationEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationTabsPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/contacts/new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationContactCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/contacts/:contact/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationContactEditPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/legal-entities/new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <LegalEntityCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/legal-entities/:entity/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <LegalEntityEditPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/domains/new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationDomainCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/domains/:domain/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationDomainEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/orders/new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="ORD" access="create">
                    <OrganizationOrderCreateView />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/orders/:order/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="ORD" access="view">
                    <OrganizationOrderEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/documents/new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationDocumentCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/documents/:document/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationDocumentEditPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/subscriptions/new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="create">
                    <OrganizationSubscriptionCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/subscriptions/:subscription/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="update">
                    <OrganizationSubscriptionEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/subscriptions/:subscription/information',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationSubscriptionInformationPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/subscriptions/:subscription/orders',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="ORD" access="view">
                    <OrganizationSubscriptionOrderPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/users/new',
                element: (
                  <AdminGuard hasContent>
                    <OrganizationUserCreatePage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/users/:user/edit',
                element: (
                  <AdminGuard hasContent>
                    <OrganizationUserEditPage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/:tab',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="view">
                    <OrganizationTabsPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CMP" access="create">
                    <OrganizationCreatePage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'contacts',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="view">
                    <ContactListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="view">
                    <ContactListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="create">
                    <ContactCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="update">
                    <ContactEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'subscriptions',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="view">
                    <SubscriptionListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="view">
                    <SubscriptionListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="create">
                    <SubscriptionCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/information',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="view">
                    <SubscriptionInformationPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/orders',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="view">
                    <SubscriptionOrderPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="SUB" access="update">
                    <SubscriptionEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'orders',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="ORD" access="view">
                    <OrderListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="ORD" access="view">
                    <OrderListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="ORD" access="create">
                    <OrderCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="ORD" access="view">
                    <OrderEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'requests',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="REQ" access="view">
                    <RequestListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="REQ" access="view">
                    <RequestListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="REQ" access="create">
                    <RequestCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'users',
                children: [
                  {
                    element: (
                      <PermissionGuard hasContent module="ACC" submodule="REQ" access="view">
                        <RequestUserListView />
                      </PermissionGuard>
                    ),
                    index: true,
                  },
                  {
                    path: ':id/detail',
                    element: (
                      <PermissionGuard hasContent module="ACC" submodule="REQ" access="view">
                        <RequestUserDetailView />
                      </PermissionGuard>
                    ),
                  },
                  // {
                  //   path: 'new',
                  //   element: (
                  //     <PermissionGuard hasContent module="ACC" submodule="REQ" access="create">
                  //       <RequestCreatePage />
                  //     </PermissionGuard>
                  //   ),
                  // },
                  {
                    path: ':id/edit',
                    element: (
                      <PermissionGuard hasContent module="ACC" submodule="REQ" access="update">
                        <RequestEditPage />
                      </PermissionGuard>
                    ),
                  },
                ],
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="REQ" access="update">
                    <RequestEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'portfolio',
        children: [
          {
            path: 'partners',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerListPage />
                  </PermissionGuard>
                ),
              },

              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="create">
                    <PartnerCreatePage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="update">
                    <PartnerEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/information/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerInformationEditPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/contacts/new',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerContactCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/contacts/:contact/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerContactEditPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/consoles/new',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerProviderCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/consoles/:provider/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerProviderEditPage />
                  </PermissionGuard>
                ),
              },

              {
                path: ':id/:tab',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRT" access="view">
                    <PartnerTabsPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'products',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRD" access="view">
                    <ProductListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRD" access="view">
                    <ProductListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRD" access="update">
                    <ProductEditPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRD" access="create">
                    <ProductCreatePage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'skus',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="SKU" access="view">
                    <SkuListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="SKU" access="view">
                    <SkuListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="SKU" access="create">
                    <SkuCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="SKU" access="update">
                    <SkuEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'plans',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PLN" access="view">
                    <PlanListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PLN" access="view">
                    <PlanListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PLN" access="create">
                    <PlanCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PLN" access="update">
                    <PlanEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
          {
            path: 'pricebook',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRB" access="view">
                    <PricebookListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRB" access="view">
                    <PricebookListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRB" access="create">
                    <PricebookCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="PRT" submodule="PRB" access="update">
                    <PricebookEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'directory',
        children: [
          {
            path: 'users',
            children: [
              {
                element: (
                  <AdminGuard hasContent>
                    <UserListPage />
                  </AdminGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <AdminGuard hasContent>
                    <UserListPage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <AdminGuard hasContent>
                    <UserEditPage />
                  </AdminGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <AdminGuard hasContent>
                    <UserCreatePage />
                  </AdminGuard>
                ),
              },
            ],
          },
          {
            path: 'us-mkt',
            children: [
              {
                element: (
                  <AdminGuard hasContent>
                    <UsMktListPage />
                  </AdminGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <AdminGuard hasContent>
                    <UsMktListPage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <AdminGuard hasContent>
                    <UsMktEditPage />
                  </AdminGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <AdminGuard hasContent>
                    <UsMktCreatePage />
                  </AdminGuard>
                ),
              },
            ],
          },
          {
            path: 'teams',
            children: [
              {
                element: (
                  <AdminGuard hasContent>
                    <TeamListPage />
                  </AdminGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <AdminGuard hasContent>
                    <TeamListPage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <AdminGuard hasContent>
                    <TeamEditPage />
                  </AdminGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <AdminGuard hasContent>
                    <TeamCreatePage />
                  </AdminGuard>
                ),
              },
            ],
          },
          {
            path: 'roles',
            children: [
              {
                element: (
                  <AdminGuard hasContent>
                    <RoleListPage />
                  </AdminGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <AdminGuard hasContent>
                    <RoleListPage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <AdminGuard hasContent>
                    <RoleEditPage />
                  </AdminGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <AdminGuard hasContent>
                    <RoleCreatePage />
                  </AdminGuard>
                ),
              },
            ],
          },
          {
            path: 'bank',
            children: [
              {
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="view">
                    <BankListPage />
                  </PermissionGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="view">
                    <BankListPage />
                  </PermissionGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="create">
                    <BankCreatePage />
                  </PermissionGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <PermissionGuard hasContent module="ACC" submodule="CON" access="update">
                    <ContactEditPage />
                  </PermissionGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        children: [
          {
            path: 'accounts',
            children: [
              { element: <AccountGeneralPage />, index: true },
              { path: 'general', element: <AccountGeneralPage /> },
              { path: 'security', element: <AccountSecurityPage /> },
              { path: 'notifications', element: <AccountNotificationsPage /> },
            ],
          },
        ],
      },
      // {
      //   path: 'integrations',
      //   children: [
      //     {
      //       path: 'apis',
      //       children: [
      //         { element: <IndexPage />, index: true },
      //         { path: 'list', element: <IndexPage /> },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  {
    path: 'cloud-compass',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'aws/accounts',
        children: [
          {
            path: 'payer-accounts',
            children: [
              {
                element: (
                  <AdminGuard hasContent>
                    <PayerAccountListView />
                  </AdminGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <AdminGuard hasContent>
                    <PayerAccountListView />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <AdminGuard hasContent>
                    <PayerAccountEditPage />
                  </AdminGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <AdminGuard hasContent>
                    <PayerAccountCreatePage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/:tab',
                element: (
                  <AdminGuard hasContent>
                    <PayerAccountTabsPage />
                  </AdminGuard>
                ),
              },
              {
                path: 'linked-accounts',
                children: [
                  {
                    path: ':id/edit',
                    element: (
                      <AdminGuard hasContent>
                        <LinkedAccountEditPage />
                      </AdminGuard>
                    ),
                  },
                  {
                    path: ':id/information',
                    element: (
                      <AdminGuard hasContent>
                        <LinkedAccountInformationPage />
                      </AdminGuard>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'gcp/accounts',
        children: [
          {
            path: 'billing-accounts',
            children: [
              {
                element: (
                  <AdminGuard hasContent>
                    <BillingAccountListView />
                  </AdminGuard>
                ),
                index: true,
              },
              {
                path: 'list',
                element: (
                  <AdminGuard hasContent>
                    <BillingAccountListView />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/:tab',
                element: (
                  <AdminGuard hasContent>
                    <BillingAccountTabsPage />
                  </AdminGuard>
                ),
              },
              {
                path: ':id/edit',
                element: (
                  <AdminGuard hasContent>
                    <BillingAccountEditPage />
                  </AdminGuard>
                ),
              },
              {
                path: 'new',
                element: (
                  <AdminGuard hasContent>
                    <BillingAccountCreatePage />
                  </AdminGuard>
                ),
              },
              {
                path: 'subaccounts',
                children: [
                  {
                    element: (
                      <AdminGuard hasContent>
                        <SubAccountListView />
                      </AdminGuard>
                    ),
                    index: true,
                  },
                  {
                    path: 'list',
                    element: (
                      <AdminGuard hasContent>
                        <SubAccountListView />
                      </AdminGuard>
                    ),
                  },
                  {
                    path: ':id/:tab',
                    element: (
                      <AdminGuard hasContent>
                        <SubAccountTabsPage />
                      </AdminGuard>
                    ),
                  },
                  {
                    path: ':id/edit',
                    element: (
                      <AdminGuard hasContent>
                        <SubAccountEditPage />
                      </AdminGuard>
                    ),
                  },
                  {
                    path: 'projects',
                    children: [
                      {
                        element: (
                          <AdminGuard hasContent>
                            <ProjectListView />
                          </AdminGuard>
                        ),
                        index: true,
                      },
                      {
                        path: 'list',
                        element: (
                          <AdminGuard hasContent>
                            <ProjectListView />
                          </AdminGuard>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
