import { Theme, alpha } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { pxToRem } from '@/theme/typography';

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1500px)': {
      fontSize: pxToRem(lg),
    },
  };
}
export function responsivePadding({ sm, md, lg }: { sm: string; md: string; lg: string }) {
  return {
    '@media (min-width:600px)': {
      padding: sm,
    },
    '@media (min-width:900px)': {
      padding: md,
    },
    '@media (min-width:1500px)': {
      padding: lg,
    },
  };
}

export function table(theme: Theme) {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.${tableRowClasses.selected}`]: {
            backgroundColor: alpha(theme.palette.primary.dark, 0.04),
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.dark, 0.08),
            },
            '&:nth-of-type(even)': {
              backgroundColor: alpha(theme.palette.primary.dark, 0.04),
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.dark, 0.08),
              },
            },
          },
          '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.table,
          },
          [`&.${tableRowClasses.hover}:hover`]: {
            backgroundColor: theme.palette.action.selected,
            transition: 'background-color 0.5s ease-out',
            // borderLeft: `1px solid ${theme.palette.secondary.main}`,
          },
          '&:last-of-type': {
            [`&.${tableCellClasses.root}`]: {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // borderBottomStyle: 'dashed',
          borderBottom: '0',
          fontSize: 14,
          ...responsiveFontSizes({ sm: 10, md: 10, lg: 14 }),
          padding: '16px 15px',
          ...responsivePadding({ sm: '5px 5px', md: '16px 5px', lg: '16px 15px' }),
        },
        head: {
          padding: '15px 15px',
          ...responsivePadding({ sm: '5px 5px', md: '5px 5px', lg: '15px 15px' }),
          fontSize: 14,
          ...responsiveFontSizes({ sm: 10, md: 10, lg: 14 }),
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightSemiBold,
          backgroundColor: theme.palette.background.neutral,
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
        },
        paddingCheckbox: {
          paddingLeft: theme.spacing(1),
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        toolbar: {
          height: 64,
        },
        actions: {
          marginRight: 8,
        },
        select: {
          paddingLeft: 8,
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
        selectIcon: {
          right: 4,
          width: 16,
          height: 16,
          top: 'calc(50% - 8px)',
        },
      },
    },
  };
}
