import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import CompactLayout from '@/layouts/compact';
import { retry } from '@/utils/common';

const Page404 = lazy(
  () => retry(() => import('@/pages/404')) as Promise<typeof import('@/pages/404')>
);

export const mainRoutes = [
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [{ path: '404', element: <Page404 /> }],
  },
];
