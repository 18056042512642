import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useRouter } from '@/routes/hooks';
import { useAuthContext } from '@/auth/hooks';
import { varHover } from '@/components/animate';
import CustomPopover, { usePopover } from '@/components/custom-popover';
import { useTranslation } from 'react-i18next';
import { paths } from '@/routes/paths';
import { PATH_AFTER_LOGIN } from '@/config-global';

export default function AccountPopover() {
  const router = useRouter();
  const { t } = useTranslation();
  const OPTIONS = [
    {
      label: t('Home'),
      linkTo: PATH_AFTER_LOGIN,
    },
  ];

  const { logout, user } = useAuthContext();

  if (user?.isAdmin) {
    OPTIONS.push({
      label: 'Settings',
      linkTo: paths.setting.general.countries.root,
    });
  }
  OPTIONS.push({
    label: t('Profile'),
    linkTo: paths.management.settings.accounts.root,
  });

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.photoURL}
          alt={user?.displayName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
          imgProps={{
            loading: 'lazy',
            sx: {
              transform: 'scale(1.2)',
            },
          }}
        >
          {user?.displayName.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 250, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          {/* {user?.position && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user?.position}
            </Typography>
          )} */}

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          {t('Logout')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
