// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from '@/hooks/use-boolean';
import { useResponsive } from '@/hooks/use-responsive';
// components
import { useSettingsContext } from '@/components/settings';
//
import { useEffect } from 'react';
import { useAuthContext } from '@/auth/hooks';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const location = useLocation();
  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;
  useEffect(() => {
    if (user && import.meta.env.PROD) {
      (window as any).intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'q2vbp4ii',
        name: `${user.firstName} ${user.lastName}`, // Full name
        user_id: user._id, // a UUID for your user
        Orioner: user.isStaff, // a UUID for your user
        user_hash: user.user_hash, // a UUID for your user
        email: user.email, // the email for your user
        created_at: user.createdAt, // Signup date as a Unix timestamp
        avatar: {
          type: 'avatar',
          image_url: user?.avatar?.url
            ? decodeURIComponent(user.avatar.url)
            : 'https://orion-csh.s3.amazonaws.com/media/utils/default-avatar.jpg',
        },
      };
      // eslint-disable-next-line func-names
      (function () {
        const w: any = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const scriptId = 'intercom-script';
          // Remove existing Intercom script if it exists
          const existingScript = d.getElementById(scriptId);
          if (existingScript) {
            existingScript.parentNode?.removeChild(existingScript);
          }
          // eslint-disable-next-line func-names
          const i: any = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
          };
          i.q = [];
          // eslint-disable-next-line func-names
          i.c = function (args: any) {
            i.q.push(args);
          };
          w.Intercom = i;
          // eslint-disable-next-line func-names
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.id = scriptId;
            s.src = 'https://widget.intercom.io/widget/q2vbp4ii';
            const x = d.getElementsByTagName('script')[0];
            x.parentNode?.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    }
    Sentry.configureScope((scope: any) => {
      // scope.setExtra('battery', 0.7);
      scope.setUser({ id: user?._id, email: user?.email });
      scope.setTag('user._id', user?._id);
      scope.setTag('user.name', `${user?.firstName} ${user?.lastName}`);
      scope.setTag('user.position', user?.position);
      scope.setTag('user.email', user?.email);
      scope.setTag('user.isStaff', user?.isStaff);
      scope.setTag('user.isAdmin', user?.isAdmin);
      const { screen } = window; // Add type declaration for 'screen' object
      scope.setTag('screen.resolution', `${screen.width}x${screen.height}px`);
      scope.setTag('screen.height', screen.height);
      scope.setTag('screen.width', screen.width);
      scope.setTag('screen.pixelDepth', screen.pixelDepth);
      scope.setTag('screen.colorDepth', screen.colorDepth);
      // scope.clear();
    });
  }, [user, location.pathname]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>{children}</Main>
      </Box>
    </>
  );
}
