// routes
import { paths } from '@/routes/paths';
import { useLocales } from '@/locales';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const data = useMemo(
    () => [
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('Settings'),
        items: [
          {
            title: t('General'),
            path: paths.setting.general.root,
            icon: 'settings',
            children: [
              { title: t('Countries'), path: paths.setting.general.countries.root },
              { title: t('Currencies'), path: paths.setting.general.currencies.root },
            ],
          },
          {
            title: t('Accounts'),
            path: paths.setting.accounts.root,
            icon: 'accountCircle',
            children: [{ title: t('Organizations'), path: paths.setting.accounts.industries.root }],
            // children: [
            //   { title: t('Industries'), path: paths.management.accounts.organizations.root },
            //   { title: t('Segments'), path: paths.management.accounts.contacts.root },
            //   { title: t('Life Cycle Stages'), path: paths.management.accounts.subcriptions.root },
            //   { title: t('Churn Reasons'), path: paths.management.accounts.subcriptions.root },
            // ],
          },
          {
            title: t('Approval Flow'),
            path: paths.setting.approvalFlow.root,
            icon: 'localstore',
            children: [
              { title: t('Orders'), path: paths.setting.approvalFlow.orders.root },
              { title: t('New User'), path: paths.setting.approvalFlow.newuser.root },
            ],
          },
          {
            title: t('Portfolio'),
            path: paths.setting.portfolio.root,
            icon: 'inventory',
            children: [
              { title: t('Product and Plans'), path: paths.setting.portfolio.productTypes.root },
            ],
          },
          {
            title: t('Finances'),
            path: paths.setting.finances.root,
            icon: 'attachMoney',
            children: [{ title: t('Payments'), path: paths.setting.finances.payments.root }],
          },
          {
            title: t('Access'),
            path: paths.setting.access.root,
            icon: 'changeCircle',
            children: [{ title: t('Logs'), path: paths.setting.access.logs.root }],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
