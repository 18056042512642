import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from '@/auth/guard';
import { LoadingScreen } from '@/components/loading-screen';
import { retry } from '@/utils/common';

import DashboardLayout from '@/layouts/management';

const DashboardPage = lazy(
  () =>
    retry(() => import('@/pages/dashboard/dashboard')) as Promise<
      typeof import('@/pages/dashboard/dashboard')
    >
);

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [{ element: <DashboardPage />, index: true }],
  },
];
