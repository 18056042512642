import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { GuestGuard } from '@/auth/guard';
import AuthClassicLayout from '@/layouts/auth/classic';
import { SplashScreen } from '@/components/loading-screen';
import { retry } from '@/utils/common';

const JwtLoginPage = lazy(
  () =>
    retry(() => import('@/pages/auth/jwt/login')) as Promise<
      typeof import('@/pages/auth/jwt/login')
    >
);
const JwtRegisterPage = lazy(
  () =>
    retry(() => import('@/pages/auth/jwt/register')) as Promise<
      typeof import('@/pages/auth/jwt/register')
    >
);
const ResetPasswordPage = lazy(
  () =>
    retry(() => import('@/pages/auth/jwt/reset-password')) as Promise<
      typeof import('@/pages/auth/jwt/reset-password')
    >
);

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <AuthClassicLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </AuthClassicLayout>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: <JwtLoginPage />,
    },
    {
      path: 'reset-password/:resetToken',
      element: <ResetPasswordPage />,
    },
    {
      path: 'register',
      element: <JwtRegisterPage />,
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
