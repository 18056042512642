import CustomPopover, { usePopover } from '@/components/custom-popover';

import IconButton from '@mui/material/IconButton';
// components
import Iconify from '@/components/iconify';
// @mui
import MenuItem from '@mui/material/MenuItem';
import { m } from 'framer-motion';
import { useCallback } from 'react';
// locales
import { useLocales } from '@/locales';
import { varHover } from '@/components/animate';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { t } = useTranslation();
  const locales = useLocales();

  const popover = usePopover();

  const handleChangeLang = useCallback(
    (newLang: string) => {
      locales.onChangeLang(newLang);
      popover.onClose();
    },
    [locales, popover]
  );
  return (
    <>
      <Tooltip title={t('Language')}>
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={popover.onOpen}
          sx={{
            width: 40,
            height: 40,
            ...(popover.open && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
        </IconButton>
      </Tooltip>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {locales.allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === locales.currentLang.value}
            onClick={() => handleChangeLang(option.value)}
          >
            <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

            {t(option.label)}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
