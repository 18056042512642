// routes
import { paths } from '@/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = import.meta.env.PROD
  ? import.meta.env.VITE_HOST_API
  : `http://${window.location.host.replace('8080', '3000')}/api/v1/`;
// console.log('HOST_API', HOST_API);
// export const HOST_API = `http://${window.location.host.replace('8080', '3000')}/api/v1/`;
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;

export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;

export const FIREBASE_API = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APPID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: import.meta.env.VITE_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  callbackUrl: import.meta.env.VITE_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = import.meta.env.VITE_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
// export const PATH_AFTER_LOGIN = paths.management.accounts.organizations.root; // as '/dashboard'

export const GOOGLE_LOGIN = `${HOST_API}auth/google`;
export const MICROSOFT_LOGIN = `${HOST_API}auth/azure-ad`;
