// import {
//   enUS as enUSAdapter,
// } from 'date-fns/locale';
// core
// import {
//   enUS as enUSCore,
// } from '@mui/material/locale';
// // data-grid
// import {
//   enUS as enUSDataGrid,
// } from '@mui/x-data-grid';
// // date-pickers
// import {
//   enUS as enUSDate,
// } from '@mui/x-date-pickers/locales';
// import merge from 'lodash/merge';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    // systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    // adapterLocale: enUSAdapter,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Spanish',
    value: 'es',
    // systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
    // adapterLocale: frFRAdapter,
    icon: 'flagpack:es',
  },
  // {
  //   label: 'Vietnamese',
  //   value: 'vi',
  //   systemValue: merge(viVNDate, viVNDataGrid, viVNCore),
  //   adapterLocale: viVNAdapter,
  //   icon: 'flagpack:vn',
  // },
  // {
  //   label: 'Chinese',
  //   value: 'cn',
  //   systemValue: merge(zhCNDate, zhCNDataGrid, zhCNCore),
  //   adapterLocale: zhCNAdapter,
  //   icon: 'flagpack:cn',
  // },
  // {
  //   label: 'Arabic',
  //   value: 'ar',
  //   systemValue: merge(arSDDataGrid, arSACore),
  //   adapterLocale: arSAAdapter,
  //   icon: 'flagpack:sa',
  // },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
