import { useMemo, useEffect, useReducer, useCallback } from 'react';
// utils
import axios, { endpoints } from '@/utils/axios';
//
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from '@/routes/hooks';
import { paths } from '@/routes/paths';
import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

function checkPermission(
  user: AuthUserType,
  module: string,
  submodule: string,
  access: string,
  ownerID?: string
) {
  if (!user) return false;
  if (user.isAdmin) return true;

  const permissions = user.permissions?.[module]?.[submodule];
  if (!permissions) return false;

  const valueKey: Record<string, string> = {
    view: 'canView',
    create: 'canCreate',
    update: 'canUpdate',
    delete: 'canDelete',
  };
  const accessType = permissions[access];
  const accessValue = permissions[valueKey[access]];

  if (accessType === 'boolean') {
    if (ownerID !== undefined) {
      return ownerID === user._id;
    }
    return accessValue;
  }

  if (accessType === 'level') {
    if (accessValue === 'assigned' && ownerID !== undefined) {
      return ownerID === user._id;
    }

    if (accessValue === 'team' && ownerID !== undefined) {
      return user.teamMemberships?.includes(ownerID);
    }

    return accessValue !== 'none';
  }
  return access in permissions;
}

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();
  const router = useRouter();

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const res = await axios.get(endpoints.auth.me);

        const { user } = res.data;

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              displayName: `${user?.firstName} ${user?.lastName}`,
              photoURL: user?.avatar?.url ? decodeURI(user?.avatar?.url) : '', // 'https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_25.jpg',
              accessToken,
              checkPermission: (
                module: string,
                submodule: string,
                access: string,
                ownerID?: string
              ) => checkPermission(user, module, submodule, access, ownerID),
            },
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username: string, password: string) => {
    const data = {
      username,
      password,
    };

    const res = await axios.post(endpoints.auth.login, data);

    const { accessToken, user } = res.data;

    setSession(accessToken);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          displayName: `${user.firstName} ${user.lastName}`,
          photoURL: user?.avatar?.url ? decodeURI(user?.avatar?.url) : '',
          accessToken,
          checkPermission: (module: string, submodule: string, access: string, ownerID?: string) =>
            checkPermission(user, module, submodule, access, ownerID),
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = res.data;

      localStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    router.replace(paths.auth.jwt.login);
    queryClient.removeQueries();
    dispatch({
      type: Types.LOGOUT,
    });
  }, [queryClient, router]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      initialize,
      login,
      register,
      logout,
    }),
    [initialize, login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
