import { useLocation } from 'react-router-dom';
import { setSession } from '@/auth/context/jwt/utils';
import { PATH_AFTER_LOGIN } from '@/config-global';

export function OauthCallbackPage() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const accessToken = query.get('token');

  setSession(accessToken);
  window.location.href = PATH_AFTER_LOGIN;

  return <></>;
}
